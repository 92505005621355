
import FmlQuestionSet from '@/FML/views/FmlQuestionSet.vue'
import QMyClientsSubmenu from '@/components/navigation/Submenus/QMyClientsSubmenu.vue';
import QLeadLookup from '@/components/utils/QLeadLookup.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QCreateLeadReferral from '@/components/utils/QCreateLeadReferral.vue'
import Vue, { defineComponent } from "vue";
import { FifScript } from "@/views/NewBusiness/FIF/FIFSurvey/FifScripts";
import Lead from "@/store/Models/Lead";
import FIFDirectToReset from './FIFDirectToReset.vue';
import FIFProcessResults from './FIFProcessResults.vue';

export default defineComponent({
  components: {
    FmlQuestionSet,
    QMyClientsSubmenu,
    QLeadLookup,
    QCreateLeadReferral,
	FIFProcessResults,
    FIFDirectToReset
},
    data() {
        return {
            response: null,
            events: [],
            step: 0,
            lead: null as null | ReturnType<typeof Lead.fields>,
            loading: false,
            create_referral_dialog: false,
            licensing: null,
            show_response: false,
            the_form: null,
            loaded: false,
            slug: 'financial-information-form',
            show_script: false,
            showDirectToReset: false,
            directToResetEventBus: new Vue(),
            DFLSME: null,
			onFmlSaveMessage: '',
        }
    },

    mounted () {
        if (this.$route.query?.lead_code) {
            this.findLead(this.$route.query.lead_code)
        }
        this.loadlicensing()
        this.loadForm(this.slug);
    },

    computed: {
        agent_code () {
            if (this.response?.AgentCode) {
                return this.response.AgentCode
            } else {
                return this.user.AgentCode
            }
        },
		expenses () {
		  return {
			'Mortgage Payments': this.getResponseSum('Mortgage Information', null, 'Payment'),
			'Insurance Payments': this.getResponseSum('Insurance Information', null, 'Premium'),
			'Savings': this.getResponseSum('Additional Assets','Savings', 'Employee Contribution'),
            'Other Monthly Expenses': this.getResponseSum('Monthly Expenses',null, 'Payment'),
		  }
		},
		expenses_total(): number {
		  return Object.values(this.expenses).reduce((acc, value) => acc + + value, 0)
		},
	  	// TODO Totals use pretty much the same logic
        income_1_total (): number {
            //grabs all repeatable fields to get the question's answers and tallies them.
		  // TODO Should use alias or something similar
            const question_ids = {
                8: 'field_1559' //question ID : answer field name
            };
            let t = 0;
            Object.keys(question_ids).forEach(id => {
                if (this.response == null || typeof this.response.FormResponseAnswers == 'undefined' || this.response.FormResponseAnswers[id].AnswerContent == null) {
                    return
                }
                this.response.FormResponseAnswers[id].AnswerContent.forEach(answer => {
                    t = t + Number(answer[question_ids[id]].AnswerContent)
                })
            })

            return t;
        },
        income_2_total (): number {
            //grabs all repeatable fields to get the question's answers and tallies them.
		    const question_ids = {
                10: 'field_1542' //question ID : answer field name
            };
            let t = 0;
            Object.keys(question_ids).forEach(id => {
                if (this.response == null || typeof this.response.FormResponseAnswers == 'undefined' || this.response.FormResponseAnswers[id].AnswerContent == null) {
                    return
                }
                this.response.FormResponseAnswers[id].AnswerContent.forEach(answer => {
                    t = t + Number(answer[question_ids[id]].AnswerContent)
                })
            })

            return t;
        },
        income_total (): number {
            return this.income_1_total + this.income_2_total;
        },
        assets_total (): number {
		  //grabs all repeatable fields to get the question's answers and tallies them.
            const question_ids = {
                6: 'field_4665' //question ID : answer field name
            };
            let value = 0;

		  Object.keys(question_ids).forEach(id => {
                if (!this?.response?.FormResponseAnswers?.[id]?.AnswerContent) {
                    return
                }
                this.response.FormResponseAnswers[id].AnswerContent.forEach(answer => {
                    value = value + Number(answer[question_ids[id]].AnswerContent)
                })
            })

			return value;
        },
        employee_contribution_overage (): number {
            //grabs all repeatable fields to get the question's answers and tallies them.
            const question_id = 6;
            const employee_field = 'field_4667'
            const employer_field = 'field_4668'
            let t = 0;
            if (this.response == null || typeof this.response.FormResponseAnswers == 'undefined' || this.response.FormResponseAnswers[question_id].AnswerContent == null) {
                return 0;
            }
            this.response.FormResponseAnswers[question_id].AnswerContent.forEach(answer => {
                //tallies up the over contribution of the employee beyond what the employer matches.
                t = t + (Number(answer[employee_field].AnswerContent) - Number(answer[employer_field].AnswerContent));
            })
            return t;
        },
        has_debt () {
            //grabs all repeatable fields to get the question's answers and tallies them.
            const question_ids = {
                11: 'field_4715' //question ID : answer field name
            };
            let t = false;
            Object.keys(question_ids).forEach(id => {
                if (this.response == null || typeof this.response.FormResponseAnswers == 'undefined' || typeof this.response.FormResponseAnswers[id] == 'undefined' || this.response.FormResponseAnswers[id].AnswerContent == null) {
                    return
                }
                this.response.FormResponseAnswers[id].AnswerContent.forEach(answer => {
                    if (Number(answer[question_ids[id]].AnswerContent) > 0) {
                        t = true
                    }
                })
            })
            return t;
        },
		overpayment_count () {
		  // TODO Should use aliases
		  const questions_map = {
			4: 'field_6269', // Mortgage Information -> overpayment
			7: 'field_4716', // Monthly Expenses -> overpayment
			11: 'field_4716', // Debt TODO That field seems to be missing from FIF
		  };

		  let count = 0
		  Object.keys(questions_map).forEach(id => {
			if (!this.response?.FormResponseAnswers?.[id]?.AnswerContent) {
			  return
			}
			const itemId = questions_map[id]
			this.response.FormResponseAnswers[id].AnswerContent.forEach(items => {
			  // TODO Do we need "true" check?
			  console.log("items[itemId].AnswerContent", items[itemId].AnswerContent);
			  if (items[itemId].AnswerContent) {
				count++
			  }
			})
		  })
		  return count;
		},
		income_after_expenses (): number {
		  return this.income_total - this.expenses_total
		},
        scripts (): Record<FifScript, boolean> {
          // See: https://quility.atlassian.net/jira/software/projects/DEVST/boards/12?selectedIssue=DEVST-1695

		  const scripts: Record<FifScript, boolean> = {
			[FifScript.DFL]: false,
			[FifScript.QRS]: false,
			[FifScript.IUL]: false,
		  }

            if (this.overpayment_count) {
			  scripts[FifScript.DFL] = true
            } else {
                if (this.employee_contribution_overage > 500) {
                    if (this.has_debt) {
					  scripts[FifScript.DFL] = true
                    } else {
					  scripts[FifScript.QRS] = true
                    }
                }

                if (this.assets_total > 20000) {
				  scripts[FifScript.QRS] = true
                }
            }
			if (
			  this.lead?.Age < 50
			  && this.overpayment_count <= 1
			  && this.income_after_expenses > 1000
			  && this.employee_contribution_overage > 0
			) {
			  scripts[FifScript.IUL] = true
			}

            if (!Object.values(scripts).some(i => i)) {
                // Show DFL by default
			  scripts[FifScript.DFL] = true
            }

			return scripts
        },
    },

    methods: {
	  onScriptFinished(script: FifScript) {
		// this.response.FormResponseAnswers[255].AnswerContent = 'QRS Team'


		const resetTypeAnswer: any = Object.values(this.response.FormResponseAnswers).find(item => item.FormQuestionTitle === 'Reset Type')
		// this.$set(this.response, "LeadAssignmentID", lead_assignment_id);

		// this.response.FormResponseAnswers[255].AnswerContent = 'QRS Team'
		resetTypeAnswer.AnswerContent = script
	  },
    //need to keep in mind if the question title changes we need to update the code.
	  getResponseSum(questionTitle, answerContentValue, valueTitle) {

		let result = 0
		if (!this.response?.FormResponseAnswers) {
		  return result
		}

		const additionalAssets = Object.values(this.response.FormResponseAnswers).find(answer => answer.FormQuestionTitle === questionTitle)


		if (!additionalAssets?.AnswerContent) {
		  return result
		}

		additionalAssets.AnswerContent.forEach(answerContent => {
		  const answerItems = Object.values(answerContent)
		  if (answerContentValue && answerItems[0].AnswerContent !== answerContentValue /* Type of Asset */ ) {
			return
		  }
		  const valueItem = answerItems.find(item => item.Title === valueTitle)
		  result += + valueItem.AnswerContent // Current Value
		})

		return result
	  },
        nextStep (data, autoSaving = false) {
            if (autoSaving) { return }

            if (this.step == 3) {
                return
            }
            if (typeof data == 'object') {
                this.step = this.step + 1
            } else {
                this.step = data
            }
        },
        logEvent (e) {
            this.events.push(e);
        },
        submittedEvent () {
            this.loading = false
            this.show_response = true
        },
        loadForm (slug) {
            this.loaded = false
            this.$set(this, "the_form", null);
            const response_id = this.$route.query.form_response_id ? this.$route.query.form_response_id : 'new';
            QuilityAPI.getFormWithSlug(slug, response_id)
                .then(data => {
                    this.$set(this, "the_form", data);
                    this.$forceUpdate();
                    this.$nextTick(() => { this.loaded = true })
                })
                .catch(err => {
                    this.showError("Whoops! Can't find that form!")
                });
            this.hasChanges = false;
        },
        getLeadFormResponse (leadcode, lead_assignment_id, form_question_id) {
            this.loading = true;
            QuilityAPI.getLeadFormResponse(form_question_id, leadcode, this.agent_code).then(json => {
                this.loading = false
                this.$set(this, "response", json.data)
                this.$set(this.response, "LeadAssignmentID", lead_assignment_id);
                this.step = 1
            }).catch(error => {
                this.isLoading = false
            })
        },
        findLead (lead) {
			let leadcode

            if (this.returnObject) {
                leadcode = lead.LeadCode
            } else {
                leadcode = lead
            }

            this.step = 0;
            this.loading = true
            // Lazily load input items
            QuilityAPI.getLead(leadcode, this.agent_code).then(json => {
                this.$set(this, 'lead', json)
                this.$set(this.response, "LeadAssignmentID", json.ID);
                this.loading = false
            }).catch(() => {
                this.isLoading = false
            });
        },
        loadlicensing () {
            if (this.user.Agent.AgentCode == 'SFG0000001') {
                this.$set(this, 'licensing', [])
                return;
            }
            QuilityAPI.getAgentLicensing(this.user.Agent, this.role).then(resp => {
                if (resp.data && resp.data.licenses) {
                    this.$set(this, 'licensing', resp.data.licenses)
                } else {
                    this.showError("Could not load this agent's licensing information!");
                }
            });
        },
        setReferralLead (lead) {
            this.lead = lead
        },
        sendEmail () {
            this.loading = true
            if (this.$refs.assets_section.loadedQuestionSet) {
                this.response.SubmittedInd = true;
                this.$nextTick(() => this.$refs.assets_section.saveResponse());
            }
        },
        onSMEConfirm(e) {
            if(('Agent' in e) && ('AgentCode' in e)){
                // DFL Fields
                this.response.FormResponseAnswers[64].AnswerContent = e.Agent
                this.response.FormResponseAnswers[63].AnswerContent = e.AgentCode
                // Dev environment
                // this.response.FormResponseAnswers[277].AnswerContent = e.Agent
                // this.response.FormResponseAnswers[278].AnswerContent = e.AgentCode

                this.DFLSME = e

                this.response.SubmittedInd = true;
                this.$nextTick(() => this.$refs.hidden_section.saveResponse());
                this.closeDialog()

                // loading get's set to false when this.$refs.hidden_section emits its 'saved' event
                this.loading = true
				this.onFmlSaveMessage = 'Your appointment has been set.'
            }
        },
        onQRSConfirm(e) {
            // QRS Fields
            this.response.FormResponseAnswers[64].AnswerContent = 'QRS Team'
            this.response.FormResponseAnswers[63].AnswerContent = 'QRS Team'
            this.response.SubmittedInd = true;
            // Dev environment
            // this.response.FormResponseAnswers[277].AnswerContent = 'QRS Team'
            // this.response.FormResponseAnswers[278].AnswerContent = 'QRS Team'

            this.$nextTick(() => this.$refs.hidden_section.saveResponse());
            this.closeDialog()

                // loading get's set to false when this.$refs.hidden_section emits its 'saved' event
            this.loading = true
			this.onFmlSaveMessage = 'Your appointment has been set.'
        },
		closeDialog () {
			if (this.show_script) {
				this.show_script = false
			}
			if (this.showDirectToReset) {
				this.closeDirectToReset()
			}
		},
		closeDirectToReset () {
			this.showDirectToReset = false
			this.directToResetEventBus.$emit('clear-form-select')
		},
		onFmlSave () {
			this.loading = false
			if (this.onFmlSaveMessage) {
				this.showSuccess(this.onFmlSaveMessage)
				this.onFmlSaveMessage = ''
			}
		},
        triggerAutoSaving() {
            if (this.$refs.assets_section) {
                this.$refs.assets_section.triggerAutoSaving()
            } else if (this.$refs.income_section) {
                this.$refs.income_section.triggerAutoSaving()
            } else {
                this.$refs.notes_section?.triggerAutoSaving()
            }
        },
    },

    watch: {
        'response.Lead.LeadCode' () {
            this.$nextTick(() => {
                this.lead = this.response.Lead
            })
        },
        'lead' (newV, oldV) {
            if (typeof newV == 'undefined'){
                return
            }
            if (oldV) {
                if (newV.LeadCode == oldV.LeadCode){
                    //this is the same lead.
                    return
                }
                this.triggerAutoSaving()
            }
            if (newV.LeadAssignmentID == this.response.LeadAssignmentID){
                return
            }
            if (this.$refs.assets_section.loadedQuestionSet) {
                this.getLeadFormResponse(newV.LeadCode, newV.LeadAssignmentID, this.$refs.assets_section.loadedQuestionSet.FormQuestions[0].ID)
            }
        },
        '$route.query.form_tab' (newV, oldV) {
            this.step = newV
        },
        '$route.query.lead_code' (newV, oldV) {
            if (typeof newV == 'undefined' || newV == null) {
                return
            }
            this.findLead(newV)
        },
        '$route.query.form_response_id' (newV, oldV) {
            //we want to load a specific response id...
            if (typeof newV != 'undefined' && newV != null) {
                //this.$refs.assets_section.loadFormResponse(newV);
                return
            }
            //this.$refs.assets_section.loadFormResponseTemplate('new');
        },
        'step' (newV) {
            setTimeout(() => {
                this.$vuetify.goTo('#panel' + newV, {
                    duration: 500,
                    easing: 'easeOutCubic',
                    offset: 150
                })
            }, 250)
        }
    },
    beforeRouteLeave(to, from, next) {
        this.triggerAutoSaving()
        next()
    },

})
