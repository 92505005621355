<template>
    <div class="text-left">
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1" id="audit_datatable">
                <v-row>
                    <v-col cols="12" sm="8" class="mb-0 pb-0">
                        <p class="q-display-3 ml-5 white--text">{{ title }}
                            <v-btn dark icon @click="refreshData">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                            <v-menu v-if="allowAo" :close-on-content-click="true">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-3"
                                    >
                                        Form Data for {{lvl_title}}
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item v-for="(lvl, key)  in level_items" :key="key">
                                        <v-checkbox
                                            v-model="filter_level"
                                            :label="lvl.text"
                                            :value="lvl.value"
                                        ></v-checkbox>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </p>
                        <p class="pt-0 mt-0 pl-5">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filters
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col v-if="hasRole(['SuperAdmin'])" cols="12" sm="4" class="pr-7 mb-0">
                        <v-text-field
                            v-model="search"
                            dense
                            dark
                            append-icon="icon-q-search"
                            label="Search"
                            single-line
                            hide-details
                            @keyup="maybeSearch"
                            @click:append="refreshData"
                        />
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->

            <div class="d-flex">
                <v-data-table dense :mobile-breakpoint="2" :items-per-page.sync="rows" v-model="selected" :headers="the_headers" :items="all_data" class="elevation-0 nowrap flex-grow-1" style="width:0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="data_loading" :server-items-length="total_items" :options.sync="options">
                    <template v-slot:item.ID="{ item }">
                        <v-btn small v-if="user.Agent.AgentID == item.AgentID || hasRole(['SuperAdmin', 'Staff', 'Exec'])" @click="viewResponse(item)">View</v-btn>
                    </template>
                    <template v-slot:item.ResponseDate="{ item }">
                        <span>{{ formatDate(item.ResponseDate) }}</span>
                    </template>
                    <template v-slot:item.SubmittedDate="{ item }">
                        <span>{{ formatDate(item.SubmittedDate) }}</span>
                    </template>
                    <template v-slot:body.append>
                        <tr>
                            <td colspan="12">
                                <v-btn @click="downloadData">Export</v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <div v-show="filtersVisible" class="ml-4" style="width: 18rem">
                    <v-container>
                        <QDatePeriodFilter v-model="datePeriod" @input="refreshData()" />
                    </v-container>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import moment from 'moment'
import { debounce } from 'vue-debounce'
import QDataTableMixin from "@/components/datatables/QDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDatePeriodFilter from '@/components/utils/QDatePeriodFilter.vue'
const { Parser } = require('json2csv');

export default {
    name: "QFormEntriesDataTable",
    props: ['title', 'slug', 'agentCode', 'defaultSortColumn', 'defaultFilterKeys', 'defaultFilters', 'customFormUrl', 'allowAo'],
    mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            //use this to "preset" a filter
            the_data: [],
            data_loading: false,
            total_num: 0,
            options: {
                'sortBy': this.defaultSortColumn ? [this.defaultSortColumn] : ['LastChangeDate'],
                'sortDesc': [true],
                itemsPerPage: 10
            },
            entity: 'audit',
            agent_code: null,
            columns: [],
            always_show: ["ID", "Action", "AgentCode", "AgentName", "LastChangeBy", "LastChangeDate", "OrderNumber"],
            personal_only: true,
            level_items: [
                {
                    text:'Personal',
                    value:'personal'
                },
                {
                    text:'Base Shop',
                    value:'baseshop'
                },
                {
                    text:'Total Agency',
                    value:'totalagency'
                }],
            filter_level: 'personal',
            datePeriod: {
                startDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
            },
            filtersVisible: false,
        }
    },
    mounted: function() {
        this.loadData(this.slug)
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            var d = [];
            var g = this
            this.the_data.forEach(function(response) {
                var row = {
                    ID: response.ID,
                    SubmittedDate : response.SubmittedDate,
                    ResponseDate : response.ResponseDate
                }
                if (typeof response.AgentName != 'undefined') {
                    row.AgentName = response.AgentName;
                    row.AgentCode = response.AgentCode;
					row.AgentEmail = response.AgentEmail;
                    row.AgentID = response.AgentID;
                }
                if (typeof response.Lead != 'undefined') {
                    row.Lead = response.Lead.FirstName + " " + response.Lead.LastName;
                    row.LeadCode = response.Lead.LeadCode;
                }
                Object.keys(response.FormResponseAnswers).forEach(function(key) {
                    var answer = response.FormResponseAnswers[key];
                    var col = !answer.useQuestionAsLabel ? g.slugify(answer.FormQuestionID + "_" + answer.FormQuestionTitle) : g.slugify(answer.FormQuestionID + "_" + answer.FormQuestionLabel)
                    if (answer.AnswerDataType == 'JSON') {
                        if (answer.formatAsCurrency === true) {
                            row[col] = answer.AnswerValue != null ? g.formatCurrency(answer.AnswerValue) : ''
                        } else {
                            row[col] = answer.AnswerValue != null ? answer.AnswerValue.substring(0, 20) : ''
                        }
                    } else {
                        if (answer.formatAsCurrency === true) {
                            row[col] = g.formatCurrency(answer.AnswerContent)
                        } else {
                            row[col] = answer.AnswerContent
                        }

                    }
                })
                d.push(row);
            })
            return d;
        },
        filter_keys: function() {
            return [];
        },
        total_items: function() {
            return this.total_num;
        },
        available_filters: function() {
            return {};
        },
        the_headers: function() {
            //go through all the available data and get a list of columns.
            var columns = [{
                'text': "",
                'value': "ID",
                'sortable': false,
            }, {
                'text': "Response Date",
                'value': "ResponseDate",
                'sortable': true,
            }, {
                'text': "Submit Date",
                'value': "SubmittedDate",
                'sortable': true,
            }];
            var has_lead = false;
            var has_agent = false;
            var g = this
            this.the_data.forEach(function(response) {
                if (typeof response.AgentName != 'undefined' && !has_agent) {
                    columns.push({
                        'text': "Agent Name",
                        'value': "AgentName",
                        'sortable': false,
                    })
                    columns.push({
                        'text': "AgentCode",
                        'value': "AgentCode",
                        'sortable': false,
                    })
					columns.push({
						'text': "Agent Email",
						'value': "AgentEmail",
						'sortable': false,
					});
                    has_agent = true;
                }
                if (typeof response.Lead != 'undefined' && !has_lead) {
                    columns.push({
                        'text': "Lead",
                        'value': "Lead",
                        'sortable': false,
                    })
                    columns.push({
                        'text': "Lead Code",
                        'value': "LeadCode",
                        'sortable': false,
                    })
                    has_lead = true
                }
            })
            var answer_columns = []
            if (this.the_data.length > 0) {
                Object.keys(this.the_data[0].FormResponseAnswers).forEach(function(key) {
                    var question = g.the_data[0].FormResponseAnswers[key]
                    if (!question.TrashedInd){
                        answer_columns.push({
                            'text': !question.useQuestionAsLabel ? question.FormQuestionTitle : question.FormQuestionLabel,
                            'value': !question.useQuestionAsLabel ? g.slugify(question.FormQuestionID + "_" + question.FormQuestionTitle) : g.slugify(question.FormQuestionID + "_" + question.FormQuestionLabel),
                            'sortable': false,
                            'order' : question.SortOrder
                        })
                    }
                })
            }
            answer_columns.sort((a, b) => {return a.order > b.order ? 1 : -1 });
            return [...columns, ...answer_columns];
        },
        lvl_title: function() {
            const fltVal = this.filter_level
            let level = this.level_items.find(lvl => lvl.value == fltVal)
            return level.text
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            const filters = {
                filter_level: this.filter_level,
                ...(this.datePeriod?.startDate && { responseStartDate: this.datePeriod.startDate }),
                ...(this.datePeriod?.endDate && { responseEndDate: this.datePeriod.endDate }),
            };
            QuilityAPI.getFormDataWithSlug(this.slug, filters, { ...opt }, this.agentCode).then(function(json) {
                g.data_loading = false
                g.$set(g, 'total_num', json.meta.total);
                g.$nextTick(function() {
                    g.$set(g, 'the_data', json.data);
                })
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
            });
            this.firstPull = false;
        }, 200),
        viewResponse: function(item) {
            if (this.customFormUrl) {
                this.$router.push({ path: this.customFormUrl, query: { 'form_response_id': item.ID } });
                return
            }
            this.$router.push({ name: 'FormView', params: { 'form_slug': this.slug }, query: { 'form_response_id': item.ID } });
        },
        downloadData: debounce(function() {
            var opt = {
                ...this.options,
                search: this.search,
            }
            var endpoint = '/api/private/forms/slug/' + this.slug + "/data";
            var params = {
                ...opt,
                ...(this.datePeriod?.startDate && { responseStartDate: this.datePeriod.startDate }),
                ...(this.datePeriod?.endDate && { responseEndDate: this.datePeriod.endDate }),
                filter_level: this.filter_level,
                'itemsPerPage': "csv",
            }
            this.dispatchJob({
                'method': "GET",
                'path': endpoint,
                'params': params,
                'JobName': this.title + " Export"
            })
        }, 200),
        toggleFilters() {
            this.filtersVisible = !this.filtersVisible
        },
    },
    watch: {
        'agentCode': function(newV, oldV) {
            this.$nextTick(this.refreshData);
        },
        'slug': function(newV, oldV) {
            if (typeof newV == 'undefined' || newV == null || newV == '') {
                return
            }
            this.$nextTick(this.refreshData);
        },
        filter_level: function(newV, oldV) {
            this.$nextTick(this.refreshData);
        },
        filtersVisible (value) {
            if (!value) {
                this.datePeriod.startDate = moment().subtract(29, 'days').format('YYYY-MM-DD')
                this.datePeriod.endDate = moment().format('YYYY-MM-DD')
            }
        },
    },
    components: {
        QDatePeriodFilter,
    },
}
</script>

<style>
.smaller-text.v-data-table td {
    font-size: .65em;
}

.smaller-text.v-data-table td {
    padding: 0px 8px 0px;
}

.smaller-text.v-data-table--dense td {}
</style>